@import "variables.css";
@import "@css/button/button.css";

nav.progress-bar > ul {
	display: flex;
	padding: 3rem 3rem 0;
}

nav.progress-bar li {
	border: 1px solid var(--color-grey);
	width: 30px;
	height: 30px;
	border-radius: 50%;
	text-align: center;
	margin-right: 1rem;
	line-height: 30px;
}

nav.progress-bar li.selected {
	background-color: var(--color-branding-dark);
	border-color: var(--color-branding-dark);
}

.nonApplicable {
	display: none;
}

.formDialogue .title {
	display: none;
}

.formAccordion {
	padding: 0;
	margin: 0;
}

.formAccordion .content {
	flex-grow: 0;
	min-height: 0;
	padding: 3rem;
}

.formAccordion .content fieldset {
	border: none;
	padding: 0;
	margin: 0;
}

.formAccordion .content .hasHeader {
	margin-bottom: 40px;
}

#S3 .formAccordion .content .hasHeader {
	margin-bottom: 0;
}

#S1 .content fieldset .headerText, #S2 .content fieldset .headerText {
	display: none;
}

.formButtons .next, .formButtons .save, .actionsBottom .next {
	text-align: center;
	white-space: nowrap;
	background: var(--button--primary-background);
	border: var(--button--primary-border);
	border-radius: var(--button--primary-border-radius);
	color: var(--button--primary-color);
	font-size: var(--button--primary-font-size);
	font-weight: var(--button--primary-font-weight);
	font-family: var(--button--primary-font-family);
	height: var(--button--primary-height);
	letter-spacing: var(--button--primary-letter-spacing);
	line-height: var(--button--primary-height);
	padding: var(--button--primary-padding);
	transition: all var(--transition-duration-short);
	box-shadow: var(--button--primary-shadow);
	position: absolute;
	bottom: 0;
	right: 30px;
	bottom: 30px;
	cursor: pointer;
}

.formButtons .previous, .actionsBottom .previous {
	text-align: center;
	white-space: nowrap;
	background: var(--button--primary-background);
	border: var(--button--primary-border);
	border-radius: var(--button--primary-border-radius);
	color: var(--button--primary-color);
	font-size: var(--button--primary-font-size);
	font-weight: var(--button--primary-font-weight);
	font-family: var(--button--primary-font-family);
	height: var(--button--primary-height);
	letter-spacing: var(--button--primary-letter-spacing);
	line-height: var(--button--primary-height);
	padding: var(--button--primary-padding);
	transition: all var(--transition-duration-short);
	box-shadow: var(--button--primary-shadow);
	position: absolute;
	bottom: 0;
	left: 30px;
	bottom: 30px;
	cursor: pointer;
}

div.button > button {
	text-align: center;
	white-space: nowrap;
	background: var(--button--primary-background);
	border: var(--button--primary-border);
	border-radius: var(--button--primary-border-radius);
	color: var(--button--primary-color);
	font-size: var(--button--primary-font-size);
	font-weight: var(--button--primary-font-weight);
	font-family: var(--button--primary-font-family);
	height: var(--button--primary-height);
	letter-spacing: var(--button--primary-letter-spacing);
	line-height: var(--button--primary-height);
	padding: var(--button--primary-padding);
	transition: all var(--transition-duration-short);
	box-shadow: var(--button--primary-shadow);
	cursor: pointer;
	margin-top: 10px;
}

.main-content .title, .main-content .mainMessage {
	font-size: var(--font-size-heading-3);
	font-size: var(--font-size);
	font-family: var(--font-family);
	line-height: var(--font-size);
	border-bottom: none;
	margin-bottom: 20px;
	padding: 3rem 3rem 0 3rem;
}

.formAccordion li .handle {
	display: none;
}

.formAccordion li.active .handle {
	display: block;
}

.formAccordion .handle {
	border-bottom: solid 1px var(--color-grey);
}

.formAccordion .handle h3 {
	font-size: var(--font-size-heading-2);
	line-height: var(--font-size-heading-2);
	margin-bottom: 20px;
	padding-left: 3rem;
	padding-right: 3rem;
}

#_AddMyClaimByPolicies__1 .actionsBottom {
	display: none;
}

.add-my-claim-by-policies-step .instructionMessage {
	display: none;
}

.popover {
	color: red;
	font-size: var(--font-size-small);
	position: absolute;
	left: 0;
	margin: 25px 0 0 50px;
}

#environmentLabel {
	display: none;
}

.entryGroupContent .entry {
	margin-bottom: 20px;
	padding: 0 3rem 0 3rem;
}

.entryGroupContent .values .value.checked.required.focus::after {
	display: none;
}

.entryGroupContent .values .verificationMessages ul li {
	color: red;
	font-size: var(--font-size-small);
	border-color: var(--color-black);
	display: none;
}

.entryGroupContent .infoText ul {
	position: relative;
}

.entryGroupContent .infoText ul li::before {
  	content: "■";
	color: var(--color-branding-dark);
	font-weight: 700;
	display: inline-block;
	position: absolute;
	left: 0;
}

.entryGroupContent ul li::before {
	content: "■";
	color: var(--color-branding-dark);
	font-weight: 700;
	display: inline-block;
	position: absolute;
	left: 25px;
}

.formAccordion li.active {
	display: block;
}

.formAccordion > li {
	display: none;
}

#_Form_FormSection > div:nth-child(3) > div {
	color: red;
	font-size: var(--font-size-small);
	position: absolute;
	bottom: 150px;
	left: 30px;
}

#_Form_FormSection > div:nth-child(4) > div {
	color: red;
	font-size: var(--font-size-small);
	position: absolute;
	bottom: 70px;
	left: 30px;
}

#_Form_FormSection > div:nth-child(5) > div {
	color: red;
	font-size: var(--font-size-small);
	position: absolute;
	bottom: 50px;
	left: 30px;
}

#S1 .content .entryGroupContent .entry {
	padding: 0;
}

#S1 .content .entryGroup:nth-child(3), #S1 .content .entryGroup:nth-child(4) {
	box-shadow: var(--shadow-small);
	padding: 3rem;
}

#S1 .content .entryGroup:nth-child(3) .entryGroupContent .entry .labels,
#S1 .content .entryGroup:nth-child(4) .entryGroupContent .entry .labels {
	display: none;
}

#S1 .content .entryGroup:nth-child(3) .entryGroupContent .entry .values .fixedValue,
#S1 .content .entryGroup:nth-child(4) .entryGroupContent .entry .values .fixedValue {
	font-size: var(--font-size-heading-2);
	font-weight: var(--font-weight-bold);
}

#S1 .content .entryGroup:nth-child(3) .entryGroupContent .entry .values .fixedValue .textBeforeInputField,
#S1 .content .entryGroup:nth-child(4) .entryGroupContent .entry .values .fixedValue .textBeforeInputField {
	margin-right: 10px;
}

#S1 .content .entryGroup .entryGroupContent .entry .labels label {
	font-size: var(--font-size-heading-3);
	font-weight: var(--font-weight-bold);
}

#S1 .content .entryGroup .entryGroupContent .entry .values .fixedValue.value {
	font-size: var(--font-size-heading-3);
	font-weight: var(--font-weight-bold);
}

#S1 .content .entryGroup .entryGroupContent .entry .values .fixedValue.value span.textBeforeInputField {
	margin-right: 5px;
}

#S2 .entryGroupContent .values input[type="email"] {
	max-width: 100%;
	border: 2px solid var(--color-black);
	line-height: 20px;
	padding: 0.75rem 1rem;
	font-size: var(--font-size);
	font-family: var(--font-family);
	min-height: 48px;
	font-weight: var(--font-weight);
	transition: none;
}

#S2 .entryGroupContent .values input[type="checkbox"] {
	border-color: var(--color-black);
}

#S2 .content {
	padding: 0;
}

#S2 .content .entryGroup:nth-child(1) {
	padding: 3rem 3rem 0 3rem;
}

h3.entryGroupHeader {
	padding-top: 20px;
}

#S3 .hasHeader.entryGroup legend {
	background-color: var(--color-grey-light);
    width: 100%;
	font-size: 18px;
	padding: 3rem;
}

#S2 .content .entryGroup:nth-child(2) .entryGroupContent .infoText:nth-child(1), #S3 .entryGroupContent .infoText {
	padding: 0 3rem 0 3rem;
}

#S2 .content .entryGroup:nth-child(2) .entryGroupContent .infoText {
	margin-top: 20px;
	padding: 0 3rem 0 3rem;
}

#S2 .content .entryGroup:nth-child(2) .entryGroupContent .entry {
	margin-top: 20px;
	margin-bottom: 0;
	padding: 3rem;
	background-color: var(--color-grey-light);
}

#S2 .content .entryGroup:nth-child(2) .entryGroupContent .entry .labels, #S2 .content .entryGroup:nth-child(2) .entryGroupContent .entry .values, #S2 .content .entryGroup:nth-child(2) .entryGroupContent .entry .values .value {
	display: flex;
	flex: 1;
	margin-right: 0;
}

#S2 .content .entryGroup:nth-child(2) .entryGroupContent .entry:last-child {
	margin-top: 0;
	padding-top: 0;
}

#S3 .radioList label {
	display: block;
	margin-bottom: 20px;
}

#S3 .radioList label:last-child {
	margin-bottom: 0;
}

#S3 .content .entryGroup:first-child {
	background-color: transparent;
	padding: 0;
}

#S3 .content .entryGroup:nth-child(3), #S3 .content .entryGroup:nth-child(5) {
	background-color: var(--color-grey-light);
}

#S3 .content .entry {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

#S3 .content .entry.nonApplicable {
	display: none;
}

#S3 .content .entry .values, #S3 .content .entry .labels {
	flex: 1;
}

#S3 .content .entry.valuesBelowLabels .values {
	flex: auto;
	margin-left: 2px;
	background-color: var(--color-grey-light);
}

#S3 .content .entry.valuesBelowLabels .labels {
	display: none;
}

#S3 .content .entry.valuesBelowLabels {
	background-color: var(--color-grey-light);
	padding-bottom: 5px;
}

#S3 .entryGroupContent .values input[type="text"] {
	border-color: var(--color-black);
}

#S3 > div.content.ng-scope > fieldset.hasHeader.entryGroup > div > div:nth-child(4) {
	margin-top: 20px;
}

#S3 > div.content.ng-scope > fieldset:nth-child(2) .entry {
	margin-bottom: 0;
	padding-bottom: 15px;
}

#S3 .entryGroupContent {
	padding-bottom: 5px;
	background-color: var(--color-grey-light);
}

#S3 .entryGroupContent .entry {
	padding-top: 5px;
}

#S3 .entryGroupContent .entry:first {
	padding-top: 10px;
}

#_AddClaimStepConfirmation_closingStatement fieldset {
	border: none;
	padding: 10px 0;
	margin: 0;
}

#_AddClaimStepConfirmation_closingStatement {
	background-color: var(--color-grey-light);
}

#_AddClaimStepConfirmation_closingStatement fieldset .entryGroupContent .entry {
	display: flex;
	justify-content: space-between;
}

#_AddClaimStepConfirmation_sectionClaimConfirmationScreenContent section:first-child  {
	padding: 0;
}

#_AddClaimStepConfirmation_sectionClaimConfirmationScreenContent section:first-child .entryGroupContent {
	padding: 0 3rem 0 3rem;
	word-break: break-word;
	hyphens: auto;
}

#_AddClaimStepConfirmation_sectionClaimConfirmationScreenContent section {
	padding: 3rem 3rem 0 3rem;
}

#_AddClaimStepConfirmation_sectionClaimConfirmationScreenContent section:first-child .entryGroupHeader {
	border-bottom: solid 1px var(--color-grey);
	padding: 0 3rem 3rem 3rem;
}

#_AddClaimStepConfirmation_closingStatement {
	padding: 0 3rem 0 3rem;
}

#_AddClaimStepConfirmation_closingStatement .title {
	display: none;
}

#_AddClaimStepConfirmation_closingStatement input[type="checkbox"]::before {
	border-color: var(--color-black);
}

#_Form_IF6129_16713 {
	border-top: none;
}

a.informationPopupToggle {
	border: 2px solid var(--color-black);
	height: 22px;
	border-radius: 75px;
	width: 22px;
	text-align: center;
	margin: 5px;
	display: inline-block;
	font-weight: bold;
}

a.informationPopupToggle.nonApplicable,
.entryGroup.nonApplicable,
.modal, .modal__content .close, .modal__content .informationTitle {
	display: none;
}

.modal.modal--padded {
	display: flex;
}

.modal .informationBlock {
	margin: auto;
}

form input {
	background-color: #fff;
}

ul.ui-autocomplete {
	padding: 4px;
	width: 248px !important;
}

ul.ui-autocomplete > li.ui-menu-item {
	background-color: white;
	border-bottom: 2px solid var(--color-black);
	padding: 2rem;
	cursor: pointer;
	width: 120%;
}

.pagewrapper--bedankt .pagewrapper__content nav.progress-bar > ul {
	padding: 0;
}

.thankyou-box {
	padding: 1.5rem;
}

.blockUI.blockMsg.blockPage img {
	height: 40px;
	width: 40px;
	background: #fff;
	border-radius: 100%;
	padding: 10%;
}

@media print {
	#keylaneMyQISApp {
		display: none;
	}
}
