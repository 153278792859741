@import "variables.css";

:root {
	--accordion__list-margin: 3rem 0 0 0;
	--accordion__list-padding: 0;
	--accordion-margin: 0 0 2rem 0;
	--accordion-background: var(--color-white);
	--accordion-border-radius: 0;
	--accordion-shadow: var(--shadow-small);
	--accordion-transition: max-height var(--transition-duration-long) cubic-bezier(.5, -.5, .5, 1.5);
	--accordion__anchor-padding: 2rem 3rem 2rem 5.5rem;
	--accordion__anchor-background: var(--accordion-background);
	--accordion__title-color: var(--color-primary);
	--accordion__title--hover-color: var(--color-primary-light);
	--accordion__title-font-size: var(--font-size-large);
	--accordion__title-font-weight: var(--font-weight-bold);
	--accordion__title-font-family: var(--font-family-secondary);
	--accordion__icon-left: 1.7rem;
	--accordion__icon-size: 1.7rem;
	--accordion__icon-color: var(--color-primary);
	--accordion__content-padding: 0 3rem 3rem 6rem;
}
