@import "panel/locals.css";

.card {
	border-radius: var(--card-border-radius);
	box-shadow: var(--card-box-shadow);
	font-size: var(--font-size);
	padding: var(--card-spacing);
}

.card__header {
	display: flex;
	align-items: center;
	text-align: center;
	font-size: var(--card__header-font-size);
	font-weight: var(--card__header-font-weight);
	font-family: var(--card__header-font-family);
	color: var(--card__header-color);
	padding: var(--card__header-spacing);
	border-top-left-radius: var(--card-border-radius);
	border-top-right-radius: var(--card-border-radius);
}

.card__icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: var(--card__icon-size);
	height: var(--card__icon-size);
	margin: var(--card__icon-margin);
	background: var(--card__icon-background);
	box-shadow: var(--card__icon-shadow);
	border-radius: var(--card__icon-border-radius);
}

.card__icon > svg {
	width: calc(var(--card__icon-size) / 2);
	height: calc(var(--card__icon-size) / 2);
}

@media only screen and (max-width: 640px) {
	.card {
		margin: 4rem auto;
		padding: 2rem;
	}

	.card__header {
		justify-content: center;
	}
}
