@import "variables.css";

.request-loader {
	width: 100%;
	text-align: center;
	padding: 25px;
}

.request-loader div {
	width: 100%;
}

.invisible {
	position: absolute;
	top: -50vh;
}