@import "@css/panel/card.css";

.card.card--side-bar {
	overflow: hidden;
	box-shadow: none;
	border: 1px solid var(--color-grey-verylight);
	margin: 0 0 20px 0;
	padding: 0;
	text-align: left;
	font-size: 14px;
}

.card.card--side-bar .card__header {
	text-align: left;
	justify-content: flex-start;
	padding: 12px;
}

.card.card--side-bar .card__content {
	padding: 12px;
	border-top: solid 1px var(--color-grey);
}

.card.card--side-bar  .card__content p {
	padding-top: 0;
	margin-top: 0;
}

.card.card--withaccordion .card__content {
	padding: 0;
	margin: 0;
	border-top: none;
}

.card.card--withaccordion .card__content p {
	padding: 0;
	margin: 0 0 5px 0;
}

.card.card--withaccordion .card__content .accordion__holder {
	padding: 8px;
}

.card.card--withaccordion .card__content .accordion__holder:last-of-type {
	border-bottom: none;
}

.card.card--withaccordion .card__content .accordion__title {
	text-decoration: underline;
	width: 95%;
}

.card.card--withaccordion .card__content .accordion-list {
	margin: 0;
}

.card.amounts {
	box-shadow: none;
	background-color: var(--color-branding);
	margin: 0;
}

.card.amounts:not(.single) {
	min-height: 120px;
	padding: 0;
}

.card.amounts.single {
	padding: 2em;
}

.card.amounts:not(.single) div.card__content {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	gap: 2em;
	padding: 2em;
}

.card.amounts:not(.single) div.card__content:not(:has(div.description)) {
	width: 75%;
}

.card.amounts div.card__content div:not(.description) {
	white-space: nowrap;
}

.card.amounts div.card__content div.description {
	padding: 0 50px;
}

.card.amounts .card__header {
	display: none;
}

.card.amounts p, .card.amounts h2 {
	margin: 0;
}

@media screen and (max-width: 768px) {
	.card.card--side-bar .card__header {
		font-size: 14px;
	}

	.card.amounts div.card__content div.description {
		padding: 0;
	}
}

@media screen and (max-width: 640px) {
	.card.amounts:not(.single) div.card__content {
		flex-direction: column;
		gap: 20px;
	}

	.card.amounts div.card__content div.description {
		padding: 0;
	}
}
