@import "variables.css";

.message-list {
	width: 100%;
	padding: 0;
	margin: 0;
	list-style: none;
	font-size: 16px;
}

.message-list.is-open {
	background-color: var(--color-grey-light);
}

.message-list > .message-item {
	cursor: pointer;
	border: 2px solid var(--color-grey);
}

.message-list:not(.message-list-vertical) > .message-item:not(:last-child) {
	border-right: none;
}

.message-list-vertical > .message-item:not(:last-child) {
	border-bottom: none;
}

.message-list > .message-item.is-unread {
	font-weight: var(--font-weight-bold);
}

.message-list > .message-item.is-selected {
	background-color: var(--color-white);
}

.message-list > .message-item > .message-item__anchor {
	position: relative;
	display: block;
	padding: 2rem 3rem 2rem 3rem;
	color: black;
	text-decoration: none;
	border-bottom: .1rem solid rgba(0, 0, 0, 0.1);
}

.message-list > .message-item .message-item__date {
	font-weight: 400;
}
