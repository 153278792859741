@import "variables.css";

.page-header {
	z-index: 1000;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	padding: 0;
	background: var(--color-branding);
}

.page-header .skip-link {
	position: absolute;
	padding-top: 10px;
	width: 100%;
	text-align: center;
	transition: top 0.3s ease;
}

.page-header .skip-link:not(:focus-within) {
	top: -40px;
	width: 1px;
	height: 1px;
	overflow: hidden;
}

.page-header .skip-link:focus-within {
	top: 0;
}

.page-header .skip-link:focus-within + .container {
	top: 40px;
}

.page-header .logo {
	position: absolute;
	left: 20px;
	top: 0;
	height: 79px;
	width: 165px;
	box-shadow: var(--shadow-asr-logo);
}

.page-header .logo .cls-1 {
	fill: #000;
}

.page-header .logo .cls-1, .cls-2, .cls-3 {
	stroke-width: 0px;
}

.page-header .logo .cls-2 {
	fill: #4ab286;
}

.page-header .logo .cls-3 {
	fill: #fff;
}

.page-header .title {
	margin: auto 0 auto 190px;
	margin-top: 60px;
	color: var(--color-black);
	font-size: var(--font-size-large);
	font-family: var(--font-family-secondary);
	text-decoration: none;
}

.page-header .title h1 {
	font-size: var(--font-size-large);
	line-height: var(--font-size-large);
	margin-bottom: 0;
}

.page-header #navigation-menu a {
	outline-offset: -3px;
}

.page-header > div > .container {
	display: flex;
	position: relative;
	top: 0;
	height: var(--header-height);
	background: var(--color-branding);
	z-index: 2000;
	transition: top 0.3s ease;
}

.page-header__accordion {
	position: absolute;
	top: 15px;
	right: 1.5rem;
	height: 2.2rem;
	background-color: transparent;
}

.page-header__accordion .accordion__holder,
.page-header__accordion .accordion__title {
	font-size: var(--font-size);
	font-weight: var(--font-weight-bold);
	width: 220px;
	border: none;
	padding: 0;
}

.page-header__accordion .accordion__holder.selected {
	background-color: white;
}

.page-header__accordion .accordion__content {
	padding: 0;
}

.page-header__accordion .accordion__header {
	padding: 12px 20px;
	border: 1px solid var(--color-grey-dark);
}

.page-header__accordion .accordion__anchor {
	position: relative;
	padding: 0;
}

.page-header__accordion .accordion__anchor:before {
	display: block;
	position: absolute;
	top: 18px;
	left: 195px;
}

.page-header__accordion .accordion__content a {
	display: block;
	padding: 12px;
	border: 1px solid var(--color-grey-dark);
	border-width: 0 1px 1px 1px;
	text-decoration: none;
	background-color: white;
}

.page-header__accordion .accordion__content a:hover {
	background-color: var(--color-grey-light);
}

.page-header__accordion .accordion__content .protected-text {
	border: 2px solid red;
	padding: 5px;
}

.page-header__accordion .accordion__icon {
	float: right;
	height: 10px;
	width: 10px;
}

.page-header__accordion a.link-account--icon {
	background: url("~assets/svg/ic-external-link.svg") no-repeat 94%;
	background-size: 10%;
	cursor: pointer;
	display: inline-block;
	width: 100%;
}

@media only screen and (max-width: 768px) {
	.page-header .title {
		margin: auto 0 auto 160px;
	}

	.page-header .logo {
		height: 63.2px;
		width: 132px;
	}

	.page-header__accordion {
		top: -1px;
	}

	.page-header__accordion .accordion__holder {
		max-width: 160px;
		width: 160px;
		top: 5px;
		left: 160px;
		font-size: 14px;
	}

	.page-header__accordion .accordion__title {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		width: 85%;
	}

	.page-header__accordion .accordion__title {
		font-size: 14px;
	}

	.page-header__accordion .accordion__anchor:before {
		display: none;
	}
}

@media only screen and (max-width: 640px) {
	.page-header .title {
		display: none;
	}
}