@import "@css/container/locals.css";
/* local variables overwrite */

:root {
	--accordion__list-margin: 1.5rem 0 1rem 0;
	/* --accordion__list-padding: 0; */
	--accordion-margin: 0;
	--accordion-background: transparent;
	/* --accordion-border-radius: 0; */
	--accordion-shadow: none;
	/* --accordion-transition: max-height var(--transition-duration-long) cubic-bezier(.5, -.5, .5, 1.5); */
	/* --accordion__anchor-padding: 2rem 3rem 2rem 6rem; */
	--accordion__anchor-background: transparent;
	--accordion__title-color: var(--color-primary);
	--accordion__title--hover-color: var(--color-primary);
	--accordion__title-font-size: 14px;
	--accordion__title-font-weight: var(--font-weight);
	--accordion__title-font-family: var(--font-family);
	--accordion__color-action-info: #2a292e;
	--accordion__color-title-selected: #949496;
	/* --accordion__icon-left: 1.5rem; */
	/* --accordion__icon-size: 2rem; */
	/* --accordion__icon-color: var(--color-primary); */
	/* --accordion__content-padding: 0 3rem 3rem 6rem; */
}
