@import "modal/locals.css";

.modal {
	z-index: 2000;
	opacity: 0;
	visibility: hidden;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	transform: translate3d(0, 0, 0); /* Force hardware acceleration */
}

.modal.is-visible {
	opacity: 1;
	visibility: visible;
}

.modal.loaded {
	transition: opacity var(--modal-transition-duration),
							visibility var(--modal-transition-duration);
}

.modal.is-visible .modal__box {
	transform: scale(1) translate3d(0, 0, 0);
}

.modal--padded .modal__content {
	padding: var(--modal--padded__content-spacing);
}

.modal__overlay {
	z-index: 100;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, .8);
}

.modal__box {
	z-index: 200;
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	max-width: var(--modal-size);
	width: calc(100% - var(--modal-min-margin));
	max-height: calc(100% - var(--modal-min-margin));
	background-color: var(--modal-background);
	box-shadow: var(--shadow-large);
	transform: scale(0) translate3d(0, 0, 0);
}

.modal.loaded .modal__box {
	transition: transform var(--modal-transition-duration);
}

.modal__content {
	overflow: auto;
	flex-grow: 0;
	max-height: 100%;
	padding: var(--modal__content-spacing);
}

.modal__header {
	display: flex;
	align-items: center;
	line-height: 1;
	padding: var(--modal__header-spacing);
	background: var(--modal__header-background);
	color: var(--modal__header-color);
	font-family: var(--modal__header-font-family);
	font-size: var(--modal__header-font-size);
}

.modal__close {
	cursor: pointer;
	margin-left: auto;
	background-color: transparent;
	border: 0;
	width: var(--modal__close-size);
	height: var(--modal__close-size);
	background-image: var(--modal__close-icon);
}

.modal__close:hover {
	outline: 2px dotted white;
}

.modal__close:focus-visible {
	outline-color: white;
}

.modal--fullscreen {
	z-index: 1999;
}

.modal--fullscreen .modal__box {
	height: 100%;
	max-height: 100%;
	box-shadow: none;
	max-width: var(--modal--fullscreen-size);
	width: calc(100% - var(--modal-min-margin));
	background: var(--modal--fullscreen-background);
}

.modal--fullscreen .modal__overlay {
	background: var(--modal--fullscreen-background);
}

.modal--fullscreen .modal__close {
	position: absolute;
	right: 40px;
	top: 0;
	margin-top: var(--modal--fullscreen__header-margin-top);
	background-image: var(--modal--fullscreen__close-icon);
}

.modal--fullscreen .modal__header {
	margin-left: 20rem;
	margin-top: var(--modal--fullscreen__header-margin-top);
	background: var(--modal--fullscreen__header-background);
}

.modal--fullscreen .modal__content {
	height: 100%;
}

@media screen and (max-width: 720px) {
	.modal {
		align-items: flex-start;
	}

	.modal__box,
	.modal--fullscreen .modal__box {
		width: 100%;
		max-height: 100%;
	}
}