@import "@css/button/button.css";

.button.button--primary,
.button.button--secondary {
	transition: background var(--transition-duration-short),
							color var(--transition-duration-short);
}

button::-moz-focus-inner {
  border: 0;
}

.button.button--big {
	line-height: 4rem;
	padding: 0 5rem;
	margin-top: 3rem;
}

.button.button--secondary {
	text-transform: none;
}

.button.button--primary.chevron::after {
	top: calc(50% - .8rem);
}

.button--link {
	background: transparent;
	text-decoration: underline;
}

.button--link:hover {
	color: var(--color-orange);
}

.button--back {
	height: 3.7rem;
	line-height: 3.7rem;
	letter-spacing: 0;
	text-align: center;
	vertical-align: middle;
	white-space: nowrap;
}

.button--back svg {
    transform: scaleX(-1);
    height: 100%;
}

.button.chevron {
	position: relative;
	padding-right: 35px;
}

.button.chevron:hover::after {
	transform: translate3d(.5rem, .1rem, 0);
}

.button.chevron::after {
	content: "";
	position: absolute;
	display: inline-block;
	top: calc(50% - 0.7rem);
	right: 20px;
	width: .8rem;
	height: 1.4rem;
	margin-left: 0.5rem;
	transform: translate3d(0, .1rem, 0);
	background-image: url("data:image/svg+xml,%3Csvg width='8' height='14' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#ffffff' d='M7.968759 7.2857051c0-.107143-.053571-.2276789-.133928-.3080361L1.593752.7365901C1.513395.6562329 1.392859.6026614 1.285716.6026614c-.107143 0-.227679.0535715-.308036.1339287l-.669644.6696437c-.080357.0803572-.133929.2008931-.133929.3080361 0 .107143.053572.2276788.133929.3080361l5.263399 5.2633991-5.263399 5.2633991c-.080357.0803573-.133929.2008931-.133929.3080361 0 .1205359.053572.2276788.133929.3080361l.669644.6696436c.080357.0803573.200893.1339288.308036.1339288.107143 0 .227679-.0535715.308036-.1339288l6.241079-6.2410788c.080357-.0803573.133928-.2008931.133928-.3080361z' fill-rule='evenodd'/%3E%3C/svg%3E");
	transition: transform var(--transition-duration-short);
}

.button.button--secondary.chevron::after {
	background-image: url("data:image/svg+xml,%3Csvg width='8' height='14' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#ffffff' d='M7.968759 7.2857051c0-.107143-.053571-.2276789-.133928-.3080361L1.593752.7365901C1.513395.6562329 1.392859.6026614 1.285716.6026614c-.107143 0-.227679.0535715-.308036.1339287l-.669644.6696437c-.080357.0803572-.133929.2008931-.133929.3080361 0 .107143.053572.2276788.133929.3080361l5.263399 5.2633991-5.263399 5.2633991c-.080357.0803573-.133929.2008931-.133929.3080361 0 .1205359.053572.2276788.133929.3080361l.669644.6696436c.080357.0803573.200893.1339288.308036.1339288.107143 0 .227679-.0535715.308036-.1339288l6.241079-6.2410788c.080357-.0803573.133928-.2008931.133928-.3080361z' fill-rule='evenodd'/%3E%3C/svg%3E");
}

.button.button--hollow.chevron::after {
	background-image: url("data:image/svg+xml,%3Csvg width='8' height='14' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#007ab3' d='M7.968759 7.2857051c0-.107143-.053571-.2276789-.133928-.3080361L1.593752.7365901C1.513395.6562329 1.392859.6026614 1.285716.6026614c-.107143 0-.227679.0535715-.308036.1339287l-.669644.6696437c-.080357.0803572-.133929.2008931-.133929.3080361 0 .107143.053572.2276788.133929.3080361l5.263399 5.2633991-5.263399 5.2633991c-.080357.0803573-.133929.2008931-.133929.3080361 0 .1205359.053572.2276788.133929.3080361l.669644.6696436c.080357.0803573.200893.1339288.308036.1339288.107143 0 .227679-.0535715.308036-.1339288l6.241079-6.2410788c.080357-.0803573.133928-.2008931.133928-.3080361z' fill-rule='evenodd'/%3E%3C/svg%3E");
}
.button.button--secondary.chevron:hover::after {
	background-image: url("data:image/svg+xml,%3Csvg width='8' height='14' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#414141' d='M7.968759 7.2857051c0-.107143-.053571-.2276789-.133928-.3080361L1.593752.7365901C1.513395.6562329 1.392859.6026614 1.285716.6026614c-.107143 0-.227679.0535715-.308036.1339287l-.669644.6696437c-.080357.0803572-.133929.2008931-.133929.3080361 0 .107143.053572.2276788.133929.3080361l5.263399 5.2633991-5.263399 5.2633991c-.080357.0803573-.133929.2008931-.133929.3080361 0 .1205359.053572.2276788.133929.3080361l.669644.6696436c.080357.0803573.200893.1339288.308036.1339288.107143 0 .227679-.0535715.308036-.1339288l6.241079-6.2410788c.080357-.0803573.133928-.2008931.133928-.3080361z' fill-rule='evenodd'/%3E%3C/svg%3E");
}

.button.button--hollow.chevron:hover::after {
	background-image: url("data:image/svg+xml,%3Csvg width='8' height='14' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#ffffff' d='M7.968759 7.2857051c0-.107143-.053571-.2276789-.133928-.3080361L1.593752.7365901C1.513395.6562329 1.392859.6026614 1.285716.6026614c-.107143 0-.227679.0535715-.308036.1339287l-.669644.6696437c-.080357.0803572-.133929.2008931-.133929.3080361 0 .107143.053572.2276788.133929.3080361l5.263399 5.2633991-5.263399 5.2633991c-.080357.0803573-.133929.2008931-.133929.3080361 0 .1205359.053572.2276788.133929.3080361l.669644.6696436c.080357.0803573.200893.1339288.308036.1339288.107143 0 .227679-.0535715.308036-.1339288l6.241079-6.2410788c.080357-.0803573.133928-.2008931.133928-.3080361z' fill-rule='evenodd'/%3E%3C/svg%3E");
}

.button--hollow {
	text-align: center;
	white-space: nowrap;
	background: transparent;
	border: solid 2px var(--color-orange);
	border-radius: var(--button--primary-border-radius);
	color: var(--color-orange);
	font-size: var(--button--primary-font-size);
	font-weight: var(--button--primary-font-weight);
	font-family: var(--button--primary-font-family);
	height: var(--button--primary-height);
	letter-spacing: var(--button--primary-letter-spacing);
	line-height: var(--button--primary-height);
	padding: var(--button--primary-padding);
	transition: all var(--transition-duration-short);
	box-shadow: var(--button--primary-shadow);
	cursor: pointer;
	text-transform: uppercase;
}

.button--hollow:hover {
	background: var(--color-orange);
	color: var(--color-white);
}

.button--hollow:disabled {
	background-color: transparent;
	border-color: var(--color-grey-light);
	color: var(--color-grey-light);
}

.button--hollow:disabled:hover {
	background-color: transparent;
	color: var(--color-grey-light);
}

.button.button--dropdown {
	min-height: 48px;
	border-radius: 0;
	border: 1px solid var(--color-grey);
	line-height: 48px;
}

.button.button.button--dropdown .button--dropdown__label {
	color: var(--color-black);
	font-size: var(--font-size);
}

.button.button.button--dropdown .button--dropdown__list {
	border-radius: 0;
}

.button.button--dropdown .button--dropdown__list .button--dropdown__item {
	font-size: var(--font-size);
}

.button.button--dropdown .button--dropdown__list .button--dropdown__item::after {
	display: none;
}

.button.button--dropdown .button--dropdown__list .button--dropdown__item:hover {
	text-decoration: underline;
	color: var(--color-black);
}

.button.button--dropdown.active {
	z-index: 9999;
}

.button.button--primary.selected {
	background-color: #000;
	color: white;
}