@import "@css/container/accordion.css";

.accordion-list {
	list-style: none;
}

.accordion-list .accordion-item {
	outline-offset: -3px;
}

.accordion__holder {
	border-top: 1px solid var(--color-grey);
	border-bottom: 1px solid transparent;
	border-left: 1px solid transparent;
	border-right: 1px solid transparent;
	text-align: left;
	margin: 0;
	padding: 4px 20px;
	font-size: 14px;
}

.accordion__holder.selected, .accordion__holder:hover {
	background-color: rgba(233, 233, 234, 0.25);
}

.accordion__holder.selected + .accordion__holder, .accordion__holder:hover + .accordion__holder {
	border-top: 1px solid var(--color-grey-verylight);
}

.accordion__holder:last-of-type {
	border-bottom: 1px solid var(--color-grey-verylight);
}

.accordion-list--chevron .accordion__anchor:after {
	display: none;
}

.accordion-list--chevron .accordion__anchor:before {
	background: transparent;
	width: 11px;
	height: 11px;
	top: 2rem;
	left: calc(100% - 11px);
	background-image: url("~assets/svg/ic-arrow_up.svg");
	transform: rotate(180deg);
	transition: transform 300ms;
}

.accordion-list--chevron .accordion__holder.selected .accordion__anchor:before {
	transform: rotate(0);
}

.accordion__anchor {
	position: relative;
	display: block;
	padding: 12px 0;
}

.accordion__container {
	overflow: hidden;
	max-height: 0;
	transition: max-height .2s ease-out;
}

.accordion__holder.selected .accordion__container {
	max-height: 1500px;
	transition: max-height .7s ease-in;
}

.accordion__hide, .accordion__show {
	display: none;
	position: absolute;
	color: var(--accordion__color-action-info);
	right: 22px;
}

.accordion__holder.selected .accordion__anchor:hover .accordion__hide,
.accordion__holder:not(.selected) .accordion__anchor:hover .accordion__show {
	display: inline-block;
}

@media screen and (max-width: 640px) {
	.accordion__holder.selected .accordion__anchor:hover .accordion__hide,
	.accordion__holder:not(.selected) .accordion__anchor:hover .accordion__show {
		display: none;
	}
	.accordion__title {
		width: 90%;
	}
}

.accordion__content {
	padding: 0 3rem 3rem 6rem;
}

.accordion__content ul {
	list-style: disc;
}

.accordion__content ul p {
	padding: 0;
	margin: 0;
}

.accordion-list--chevron .accordion__holder .accordion__anchor,
.accordion-list--chevron .accordion__holder .accordion__content {
	padding-left: 0;
}
