@import "@css/modal/locals.css";
/* local variables overwrite */

:root {
	/* --modal-size: 90rem; */
	/* --modal-min-margin: 6rem; */
	/* --modal-background: var(--color-white); */
	/* --modal__content-spacing: 0; */

	--modal__header-background: var(--color-primary);
	/* --modal__header-color: var(--color-white); */
	--modal__header-font-family: var(--font-family-bold);
	/* --modal__header-font-size: var(--font-size-heading-2); */
	/* --modal__header-spacing: 2rem 3rem; */

	/* --modal-transition-duration: var(--transition-duration-short); */

	/* --modal__close-size: 2rem; */
	--modal__close-icon: url("data:image/svg+xml,%3Csvg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3C/defs%3E%3Cg id='Artwork' stroke='none' stroke-width='1' fill-rule='evenodd'%3E%3Cg id='4dossier' transform='translate%28-1074.000000, -164.000000%29' fill='%23ffffff'%3E%3Cg id='popup'%3E%3Cg id='x' transform='translate%281074.000000, 164.000000%29'%3E%3Crect id='Rectangle-6' transform='translate%2810.000000, 10.000000%29 rotate%2845.000000%29 translate%28-10.000000, -10.000000%29 ' x='9' y='-2' width='2' height='24'%3E%3C/rect%3E%3Crect id='Rectangle-6' transform='translate%2810.000000, 10.000000%29 rotate%28135.000000%29 translate%28-10.000000, -10.000000%29 ' x='9' y='-2' width='2' height='24'%3E%3C/rect%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");

	/* --modal--padded__content-spacing: 2rem 3rem; */

	/* --modal--fullscreen-size: 114rem; */
	/* --modal--fullscreen-background: var(--modal-background); */
	/* --modal--fullscreen__header-margin-top: 3rem; */
	/* --modal--fullscreen__header-background: transparent; */
}