@import "variables.css";

.row.documents-table__row {
	border-top: 1px solid var(--color-grey);
	margin: 0;
}

.row.documents-table__row.documents-table__row--heading {
	font-weight: var(--font-weight-bold);
	border-top: 0px solid var(--color-grey);
}

.row.documents-table__row.documents-table__row--heading .row__item {
	margin-block-start: 1em;
	margin-block-end: 1em;
}

.documents-table__row .row__item {
	padding: 0;
	word-break: break-all;
}

.documents-table__row .row__item:first-child .item__value {
	word-break: break-all;
	padding-right: 20px;
}

.documents-table__row .row__item .item__value.item--status {
	display: inline-block;
}

.documents-table__row .row__item .button--secondary {
	display: inline-block;
	margin-top: 0;
}

.documents-table__row .row__item.row__item--status {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-right: 0px;
}

.documents-table .documents-table__content .content__heading:last-of-type {
	margin-top: 30px;
}

.documents-table .documents-table__content .content__heading.heading--single {
	margin-top: 0px;
}

@media only screen and (max-width: 768px) {
	.documents-table__row .row__item .item__value.item--status {
		margin-right: 20px;
	}
}

@media only screen and (max-width: 640px) {
	.documents-table__row .row__item .item__value.item--status {
		margin-right: 0px;
	}

	.documents-table .documents-table__content .content__heading {
		margin-bottom: 5px;
	}
}