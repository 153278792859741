@import "form/locals.css";

input,
select,
textarea {
	border: 0;
	font-size: 1.8rem;
	font-weight: 700;
	height: auto;
	padding: .5rem;
	width: 100%;
	background: none;
	border-bottom: .3rem solid var(--color-grey-verylight);
	transition: border-color var(--transition-duration-short);
}

.input-label {
	position: relative;
	display: block;
	width: 100%;
	margin: 1.5rem 0 2.5rem;
}

.input-label__label {
	position: absolute;
	top: .8rem;
	left: 0;
	transition: top var(--transition-duration-short);
}

.input-label input {
	padding-right: 0;
}

.input-label input:valid {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='13'%3E%3Cpath fill='%2398C931' fill-rule='evenodd' d='M16.284618 3.2566848c0 .2511164-.100446.5022328-.28125.6830365l-8.638403 8.6384032c-.180804.1808038-.43192.2812503-.683037.2812503-.251116 0-.502233-.1004465-.683036-.2812503L.996654 7.5758864C.81585 7.3950826.715403 7.1439662.715403 6.8928499c0-.2511164.100447-.5022328.281251-.6830366l1.366073-1.366073c.180803-.1808038.43192-.2812504.683036-.2812504.251117 0 .502233.1004466.683037.2812504l2.953128 2.9631732 6.589294-6.5993383c.180804-.1808038.43192-.2812503.683036-.2812503.251117 0 .502233.1004465.683037.2812503l1.366073 1.3660731c.180804.1808038.28125.4319201.28125.6830365z'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: right center;
}

.input-label input:focus + .input-label__label,
.input-label input:valid + .input-label__label {
	top: -2rem;
}
