@import "variables.css";

.pagewrapper__nav .nav__buttons .button.button--secondary {
	background-color: var(--color-grey-verylight);
	border-color: var(--color-grey-verylight);
	color: var(--color-grey-dark);
	margin-right: 0px;
}

.pagewrapper__nav .nav__buttons .button.button--secondary.hasFile {
	display: block;
	background-color: var(--color-black);
	border-color: var(--color-black);
	color: var(--color-white);
}

.upload__optiontitle {
	margin-top: 40px;
	font-weight: var(--font-weight-bold);
}

.upload__optiontitle.errorSelect {
	position: relative;
}

.upload__optiontitle.errorSelect::before {
	content: 'Selecteer één of meerdere documenten';
	color: red;
	font-size: var(--font-size-smaller);
	font-weight: var(--font-weight);
	position: absolute;
	top: -20px;
}

.upload__options .options__item {
	margin-bottom: 30px;
	vertical-align: top;
}

.uploaded-message {
	padding: 0 1.5rem;
}

button.button.button--secondary.singleupload.hasFile {
	margin-right: 10px;
}
