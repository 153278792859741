@import "variables.css";

:root {
	--button-text-transform: none;

	--button--primary-height: 4rem;
	--button--primary-padding: 0 24px;
	--button--primary-background: var(--gradient-primary);
	--button--primary-color: var(--color-white);
	--button--primary-font-size: var(--font-size);
	--button--primary-font-weight: var(--font-weight);
	--button--primary-font-family: var(--font-family);
	--button--primary-letter-spacing: 0;
	--button--primary-border: none;
	--button--primary-border-radius: var(--border-radius);
	--button--primary--hover-background: var(--gradient-secondary);
	--button--primary--hover-color: var(--button--primary-color);
	--button--primary-shadow: none;

	--button--secondary-height: var(--button--primary-height);
	--button--secondary-padding: var(--button--primary-padding);
	--button--secondary-background: var(--color-white);
	--button--secondary-color: var(--color-black);
	--button--secondary-font-size: var(--font-size);
	--button--secondary-font-weight: var(--font-weight);
	--button--secondary-font-family: var(--font-family);
	--button--secondary-letter-spacing: 0;
	--button--secondary-border: var(--button--primary-border);
	--button--secondary-border-radius: var(--border-radius);
	--button--secondary--hover-background: var(--button--primary--hover-background);
	--button--secondary--hover-color: var(--color-white);
	--button--secondary-shadow: none;

	--button--disabled-background: var(--color-grey-light);
	--button--disabled-color: var(--color-white);
	--button--disabled--hover-background: var(--color-grey-light);
	--button--disabled--hover-color: var(--color-white);

	--button--tab-height: 4.5rem;
	--button--tab-padding: 0;
	--button--tab-background: var(--button--primary-background);
	--button--tab-color: var(--button--primary-color);
	--button--tab-font-size: var(--button--primary-font-size);
	--button--tab-font-weight: var(--button--primary-font-weight);
	--button--tab-font-family: var(--button--primary-font-family);
	--button--tab-letter-spacing: var(--button--primary-letter-spacing);
	--button--tab-border: var(--button--primary-border);
	--button--tab-border-radius: var(--button--primary-border-radius);
	--button--tab--hover-background: var(--button--primary--hover-background);
	--button--tab--hover-color: var(--button--primary--hover-color);
	--button--tab--active-font-weight: var(--font-weight-bold);
	--button--tab-shadow: none;

	--button--dropdown-height: 3rem;
	--button--dropdown-min-width: 17rem;
	--button--dropdown-padding: 0 5.4rem 0 3rem;
	--button--dropdown-background: var(--color-white);
	--button--dropdown-color: var(--color-grey-light);
	--button--dropdown-text-transform: none;
	--button--dropdown-text-decoration: none;
	--button--dropdown-text-align: center;
	--button--dropdown-font-size: var(--font-size-small);
	--button--dropdown-font-family: var(--font-family);
	--button--dropdown-border-color: var(--button--dropdown-color);
	--button--dropdown-border-radius: calc(var(--button--dropdown-height) / 2);
	--button--dropdown-transition-duration: var(--transition-duration-short);
	--button--dropdown__icon-image: url("data:image/svg+xml,%3Csvg width='14' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.969 1.286a.461.461 0 0 1-.134.308l-6.241 6.24c-.08.081-.201.135-.308.135a.461.461 0 0 1-.308-.134L.737 1.594a.461.461 0 0 1-.134-.308c0-.107.053-.228.134-.308l.67-.67a.436.436 0 0 1 .307-.134c.107 0 .228.054.308.134l5.264 5.263L12.549.308c.08-.08.201-.134.308-.134.107 0 .228.054.308.134l.67.67c.08.08.134.2.134.308z' fill='%230099ED' fill-rule='evenodd'/%3E%3C/svg%3E");
	--button--dropdown__icon-width: 14px;
	--button--dropdown__icon-height: 8px;
	--button--dropdown__icon-right: 3rem;
	--button--dropdown__list-background: var(--button--dropdown-background);
	--button--dropdown__list--mobile-min-width: 13rem;
	--button--dropdown__list--mobile-padding: .75rem 0;
	--button--dropdown__item-color: var(--color-black);
	--button--dropdown__item-padding: 0 3.5rem 0 2rem;
	--button--dropdown__item-font-size: var(--font-size-small);
	--button--dropdown__item-font-weight: var(--font-weight);
	--button--dropdown__item--hover-color: var(--color-grey);
	--button--dropdown__item__icon-image: url("data:image/svg+xml,%3Csvg width='5' height='9' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.648 4.5a.27.27 0 0 1-.078.18L.93 8.32a.27.27 0 0 1-.18.078.27.27 0 0 1-.18-.078l-.39-.39a.254.254 0 0 1-.078-.18.27.27 0 0 1 .078-.18L3.25 4.5.18 1.43a.27.27 0 0 1-.078-.18.27.27 0 0 1 .078-.18L.57.68A.27.27 0 0 1 .75.602.27.27 0 0 1 .93.68l3.64 3.64a.27.27 0 0 1 .078.18z' fill='%230099ED' fill-rule='evenodd'/%3E%3C/svg%3E");
	--button--dropdown__item__icon-width: 5px;
	--button--dropdown__item__icon-height: 8px;
	--button--dropdown__item__icon-right: 2rem;

	--button--hamburger__icon-color: var(--color-primary-dark);
}
