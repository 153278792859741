@import "@css/form/locals.css";
/* local variables overwrite */

:root {
	/* --rangeslider-border-radius: var(--border-radius); */
	/* --rangeslider-background: var(--color-white); */
	/* --rangeslider-height: 3rem; */
	/* --rangeslider-shadow: var(--shadow-short); */
	/* --rangeslider__indicator-size: 4rem; */
	/* --rangeslider__indicator-background: var(--color-primary-dark); */
	/* --rangeslider__indicator-icon: url("data:image/svg+xml,%3Csvg%20width%3D%2241%22%20height%3D%2210%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%0A%20%3Cg%3E%0A%20%20%3Ctitle%3Ebackground%3C%2Ftitle%3E%0A%20%20%3Crect%20fill%3D%22none%22%20id%3D%22canvas_background%22%20height%3D%2212%22%20width%3D%2243%22%20y%3D%22-1%22%20x%3D%22-1%22%2F%3E%0A%20%3C%2Fg%3E%0A%20%3Cg%3E%0A%20%20%3Ctitle%3ELayer%201%3C%2Ftitle%3E%0A%20%20%3Cpath%20stroke%3D%22null%22%20id%3D%22svg_5%22%20fill-rule%3D%22evenodd%22%20fill%3D%22%23ffffff%22%20d%3D%22m10.562507%2C8.656253zm-10.562507%2C-3.656253l5.281253%2C-3.656253l0%2C7.312507l-5.281253%2C-3.656253z%22%2F%3E%0A%20%20%3Cpath%20transform%3D%22rotate%28180%2035.718746185302734%2C4.999999046325684%29%20%22%20stroke%3D%22null%22%20id%3D%22svg_6%22%20fill-rule%3D%22evenodd%22%20fill%3D%22%23ffffff%22%20d%3D%22m40.999999%2C8.656253zm-10.562507%2C-3.656253l5.281253%2C-3.656254l0%2C7.312507l-5.281253%2C-3.656253z%22%2F%3E%0A%20%3C%2Fg%3E%0A%3C%2Fsvg%3E"); */
	/* --rangeslider__indicator-border-radius: 50%; */
	/* --rangeslider__indicator-shadow: var(--shadow-short); */
	/* --rangeslider__indicator-color: var(--color-white); */
	/* --rangeslider__indicator-font-weight: var(--font-weight-bold); */
	/* --rangeslider__indicator-font-size: var(--font-size-large); */
	/* --rangeslider__numbers-padding: 0 20px; */
	/* --rangeslider__numbers-color: var(--color-grey); */
	/* --rangeslider__numbers-color-disabled: var(--color-grey-light); */
	/* --rangeslider__numbers-font-weight: var(--font-weight-bold); */

	/* --file-field-margin: 1rem auto; */
	/* --file-field__button-font-size: var(--font-size); */
	/* --file-field__label-padding: 1rem 0 0 0; */
	/* --file-field__label-margin: 0 2rem 0 0; */
	/* --file-field__label-border-bottom: 0.3rem solid var(--color-grey-verylight); */

	/* --checkbox__indicator-background: var(--color-grey); */
	--checkbox__indicator-background-selected: var(--color-orange);
	/* --checkbox__indicator-color: var(--color-white); */
	/* --checkbox__label-color: initial; */
	/* --checkbox__label-font-size: var(--font-size); */

	/* --age-select-border-bottom: .3rem solid #CCCCCC; */
	/* --age-select-font-size: var(--font-size); */
	/* --age-select-font-weight: var(--font-weight-bold); */
	/* --age-select-background-image: url("data:image/svg+xml,%3Csvg width='11' height='6' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.226575.9642761c0 .0803573-.040178.1707592-.100446.2310271L5.4453197 5.8761123c-.060268.060268-.1506698.1004466-.2310271.1004466-.0803572 0-.1707591-.0401786-.231027-.1004466L.3024564 1.1953032C.2421885 1.1350353.2020099 1.0446334.2020099.9642761c0-.0803572.0401786-.1707591.1004465-.231027L.8046891.2310163c.060268-.0602679.1406252-.1004465.2310271-.1004465.0803572 0 .1707591.0401786.2310271.1004465l3.9475493 3.9475494L9.161842.2310163c.0602679-.0602679.1506698-.1004465.231027-.1004465.0803573 0 .1707592.0401786.2310271.1004465l.5022329.5022328c.060268.0602679.100446.1506698.100446.231027z' fill='%23F25C36' fill-rule='evenodd'/%3E%3C/svg%3E"); */
}
