@import "@css/generic/list.css";

.list-item {
	padding: 1rem 0;
}

.list--anchor .list-item {
	font-size: var(--font-size);
}

.list--anchor .list-item:first-child {
	border-top: none;
	border-bottom: 0.1rem solid var(--color-grey);
}

.list--anchor .list-item__anchor {
	position: relative;
	text-decoration: none;
	display: block;
	color: black;
}

.list--anchor .list-item__anchor::after {
	content: "";
	display: block;
	position: absolute;
	top: calc(50% - 1rem);
	right: 0;
	width: 2rem;
	height: 2rem;
	background-image: url("~assets/svg/chevron-circle.svg");
}
