<template>
	<span>
		<label class="input-label">Uw gegevens</label>
		<label class="input-label">E-mailadres *</label>
		<input 
			class="contact"
			type="email"
			autocomplete="email"
			v-model="customerContactDetails.emailAddress1"
			:placeholder="placeHolderText"
			@focus="$emit('focusEmail', { label: 'E-mailadres', isRequired: true })"
		/>
		
		<accessible-error
			:show-error="submitted && !validCustomerEmailAddress"
			:error-message="emailAddressError"
		/>

		<label class="input-label">Mobiel telefoonnummer *</label>
		<input 
			class="contact" 
			type="tel"
			autocomplete="tel"
			v-model="customerContactDetails.telephoneNumberMobile"
			:placeholder="placeHolderText"
			@focus="$emit('focusMobilePhone', { label: 'Mobiel telefoonnummer', isRequired: true })"
		/>
		<accessible-error
			:show-error="submitted && !validMobilePhoneNumber"
			:error-message="mobilePhoneNumberError"
		/>
	</span>
</template>

<script lang="ts">
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Prop, Watch } from "vue-property-decorator"
	import { email } from "lib/validation/formats"
	import { CustomerContactDetails } from "leven-shared-components/typings/interfaces"
	import AccessibleError from "./AccessibleError.vue"

	@Component({
		components: {
			AccessibleError
		}
	})
	export default class IntermediaryContact extends Vue {
		@Prop({type: Boolean, required: true}) submitted!: boolean
		@Prop({type: Object, required: true}) customerContactDetails!: CustomerContactDetails

		placeHolderText = "Voor als wij nog vragen hebben"
		emailAddressError = "Vul a.u.b een geldig e-mailadres in."
		mobilePhoneNumberError = "Vul a.u.b een geldig mobiel telefoonnummer in."
		mounted() {
			this.changeContactDetails()
		}

		@Watch("customerContactDetails", { deep: true })
		changeContactDetails() {
			const contactDetailsValid = this.validCustomerEmailAddress && this.validMobilePhoneNumber

			const contactDetailsString = `Uw contactgegevens:\nE-mailadres: ${this.customerContactDetails.emailAddress1}\nTelefoonnummer: ${this.customerContactDetails.telephoneNumberMobile}`

			this.$emit("changedContactDetails", { contactDetailsString, contactDetailsValid, customerContactDetails: this.customerContactDetails })
		}

		get validCustomerEmailAddress() {
			if (this.customerContactDetails.emailAddress1) {
				this.customerContactDetails.emailAddress1 = this.customerContactDetails.emailAddress1.replace(/\s/g, "")
				return email(this.customerContactDetails.emailAddress1)
			}
			return false
		}

		get validMobilePhoneNumber() {
			if (this.customerContactDetails.telephoneNumberMobile) {
				return /^[+]?[0-9]{1,4}[\s\0-9]*$/.test(this.customerContactDetails.telephoneNumberMobile)
			}
			return false
		}

		@Watch("submitted")
		@Watch("validCustomerEmailAddress")
		@Watch("validMobilePhoneNumber")
		checkErrors() {
			if (this.submitted) {
				if (!this.validCustomerEmailAddress) {
					this.$emit('error', { componentUid: 'e-mailadres', errorMessage: this.emailAddressError })
				}
				if (!this.validMobilePhoneNumber) {
					this.$emit('error', { componentUid: 'mobiel nummer', errorMessage: this.mobilePhoneNumberError })
				}
			}
		}
	}
</script>

<style>
	.input-label {
		margin: 0;
		position: unset;
	}
</style>
