@import "@css/modal/modal.css";

#mutation-form-holder button.modal__close, #mutation-form-holder button.modal__close:hover {
	background-image: var(--modal__close-icon);
	background-color: transparent;
	border: none;
	height: 2rem;
	margin-top: 0;
	padding: 0;
}

.modal--padded.flex .modal__box {
	height: calc(100% - 6rem);
}

.modal--padded.flex .modal__content {
	display:flex;
}

.modal--fullscreen .modal__close {
	margin-left: 0;
	left: 32px;
	top: 35px;
	background-image: var(--modal__close-icon);
}
