@import "variables.css";

:root {
	--card-spacing: 3rem;
	--card-border-radius: var(--border-radius);
	--card-box-shadow: var(--shadow-large);
	--card__icon-size: 4rem;
	--card__icon-margin: 0 1.5rem 0 0;
	--card__icon-background: var(--color-white);
	--card__icon-border-radius: 50%;
	--card__icon-shadow: var(--shadow-small);
	--card__header-font-size: var(--font-size-heading-3);
	--card__header-font-weight: var(--font-weight-bold);
	--card__header-font-family: var(--font-family-secondary);
	--card__header-color: var(--color-black);
	--card__header-spacing: 0 0 0.5rem 0;

	--jumbotron-background: var(--gradient-primary);
	--jumbotron-border-radius: var(--border-radius);
	--jumbotron-padding: 5rem 3rem;
	--jumbotron-padding-mobile: 2.5rem;
	--jumbotron-text-align: center;
	--jumbotron__title-font-size: var(--font-size-heading);
	--jumbotron__title-font-size-mobile: var(--font-size-heading-2);
	--jumbotron__title-color: var(--color-white);
	--jumbotron__subtitle-font-size: var(--font-size);
	--jumbotron__subtitle-color: var(--color-white);
}
