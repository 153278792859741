@import "variables.css";

.dropzone {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	min-height: 180px;
	background-color: var(--color-white);
	border: 2px dashed var(--color-grey-dark);
	padding: 6px 8px;
}

.dropzone:focus-within {
	outline: 3px dotted black;
	outline-offset: 2px;
}

.dropzone.uploaded {
	border: none;
	height: 100px;
	box-shadow: var(--shadow-large);
	align-items: flex-start;
	padding: 2rem;
}

.dropzone__title {
	margin-bottom: 5px;
	margin-top: 5px;
}

.dropzone__label {
	font-size: var(--font-size-smaller);
	text-decoration: underline;
}

.dropzone__label:hover {
	text-decoration: none;
	cursor: pointer;
}

.dropzone__file .file__metadata {
	margin-top: 10px;
	color: var(--color-grey-dark);
}

.dropzone__file .file__remove svg 	{
	height: 20px;
	margin-left: 15px;
	vertical-align: middle;
	width: 20px;
}

.wrapperdropzone.errorUpload > .dropzone{
	border-color: red;
}

.wrapperdropzone.errorInvalidFile > .dropzone:after {
	content: 'Upload een pdf, doc, tiff, tif, jpg, jpeg, docx, msg, PDF, DOC, TIFF, TIF, JPG, JPEG, DOCX of MSG bestand van maximaal 30 MB.';
	color: red;
	font-size: var(--font-size-smaller);
	text-align: center;
}