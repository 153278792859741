@import "variables.css";

.pagewrapper {
	background: var(--color-white);
	width: 50%;
	max-width: 50%;
	margin: 0 auto;
	position: relative;
}

.pagewrapper__heading {
	padding: 3rem;
}

.pagewrapper__title {
	margin-bottom: 0px;
}

.pagewrapper__title.title--small {
	font-size: var(--font-size);
	font-family: var(--font-family);
	line-height: var(--font-size);
	border-bottom: none;
	margin-bottom: 20px;
}

.pagewrapper__subtitle {
	margin-bottom: 0px;
}

.pagewrapper__content {
	min-height: 50vh;
}

.pagewrapper__content, .pagewrapper__nav {
	padding: 3rem 1.5rem;
}

.pagewrapper__content .card {
	margin-bottom: 40px;
}

.pagewrapper__content .card .card__title {
	text-align: left;
}

.pagewrapper__content .card__header {
	padding: 0 0 .5rem;
}

.pagewrapper__nav {
	min-height: 100px;
}

.pagewrapper__nav .button--back {
	position: absolute;
	bottom: 0;
	margin-bottom: 3rem;
}

.pagewrapper__nav .nav__buttons {
	float: right;
}

.pagewrapper__nav .nav__buttons .button:first-child {
	margin-right: 20px;
}

.pagewrapper--widget .pagewrapper__content,
.pagewrapper--profile .pagewrapper__content {
	padding: 0;
}

.pagewrapper--upload .pagewrapper__content {
	background-color: var(--color-grey-blue);
	padding: 1rem;
}

.pagewrapper--upload .pagewrapper__nav .nav__buttons .button:first-child {
	margin-right: 0;

}

@media only screen and (max-width: 1140px) {
	.pagewrapper {
		width: 70%;
    	max-width: 70%;
	}
}

@media only screen and (max-width: 640px) {
	.pagewrapper {
		width: 100%;
		min-width: 100%;
		max-width: 100%;
	}

	.pagewrapper__heading {
		padding: 3rem 1.5rem 0;
		border-bottom: 0;
		padding-bottom: 0;
	}

	.pagewrapper__title {
		font-size: var(--font-size-heading-3);
		word-break: break-all;
	}

	.pagewrapper__title.title--small {
		margin-bottom: 10px;
	}

	.pagewrapper__content {
		padding: 0 1.5rem;
		padding-top: 0;
	}

	.pagewrapper__subtitle {
		font-size: 22px;
		margin-bottom: 20px;
	}

	.pagewrapper__nav {
		padding: 0 1.5rem;
		display: flex;
    	flex-direction: column-reverse;
		padding-bottom: 3rem;
	}

	.pagewrapper .pagewrapper__nav .button--back {
		position: absolute;
		left: 0px;
		bottom: 20px;
		margin-left: 1.5rem;
	}

	.pagewrapper__nav .nav__buttons {
		display: flex;
    	flex-direction: column-reverse;
		align-items: flex-end;
	}

	.pagewrapper .pagewrapper__nav .button--back.relative {
		position: relative;
		bottom: 0;
		text-align: left;
		margin-top: 30px;
		margin-left: 0;
	}

	.pagewrapper .pagewrapper__nav .nav__buttons .button--primary {
		margin-right: 0px;
	}

	.pagewrapper__nav .nav__buttons .button--secondary {
		margin-bottom: 20px;
	}

	.pagewrapper--documents .pagewrapper__content {
		padding: 0rem 1.5rem 3rem 1.5rem;
    	margin-bottom: 3rem;
	}

	.pagewrapper--upload .pagewrapper__content {
		padding: 3rem 1.5rem 3rem 1.5rem;
		margin-bottom: 3rem;
		min-height: 0;
	}

	.pagewrapper--upload .pagewrapper__nav {
		flex-direction: row;
		justify-content: space-between;
	}
	.pagewrapper--upload .pagewrapper__nav .nav__buttons{
		flex-direction: row;
		align-items: center;
	}
	.pagewrapper--upload .pagewrapper__nav .nav__buttons .button{
		margin-bottom: 0;
		margin-right: 0;
	}

	.pagewrapper--profile .pagewrapper__content {
		padding: 0 1.5rem;
	}
}

@media only screen and (max-width: 320px) {
	.pagewrapper .pagewrapper__title {
		font-size: var(--font-size-heading-3);
	}
}