@import "variables.css";
@import "@css/form/labeled-input.css";

.input-label {
	font-family: var(--font-family-secondary);
	font-size: var(--font-size);
	margin: 2.5rem 0 6rem;
}

.input-label input:valid {
	background: none;
}

.input-label input:focus + .input-label__label,
.input-label input:valid + .input-label__label {
	top: -3.5rem;
}

.input-label__label {
	top: -3.5rem;
}

textarea {
	min-height: 200px;
	min-width: 100%;
	resize: none;
	border: 2px solid var(--color-grey);
	padding: .75rem 1rem;
	font-family: var(--font-family);
	font-weight: var(--font-weight);
	font-size: var(--font-size);
}

select {
	border-bottom: none;
	padding: 0;
	font-weight: 400;
	font-size: var(--font-size);
	font-family: var(--font-family);
}