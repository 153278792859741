@import "button/locals.css";

.button,
button a {
	border: 0;
	cursor: pointer;
	display: inline-block;
	font-family: var(--font-family);
	text-decoration: none;
	user-select: none;
	text-transform: var(--button-text-transform);
}

.button a {
	width: 100%;
}

.button:disabled,
.button.disabled {
	background: var(--button--disabled-background);
	color: var(--button--disabled-color);
	cursor: default;
}

.button:disabled:hover,
.button.disabled:hover {
	background: var(--button--disabled--hover-background);
	color: var(--button--disabled--hover-color);
}

.button--primary {
	text-align: center;
	white-space: nowrap;
	background: var(--button--primary-background);
	border: var(--button--primary-border);
	border-radius: var(--button--primary-border-radius);
	color: var(--button--primary-color);
	font-size: var(--button--primary-font-size);
	font-weight: var(--button--primary-font-weight);
	font-family: var(--button--primary-font-family);
	height: var(--button--primary-height);
	letter-spacing: var(--button--primary-letter-spacing);
	line-height: var(--button--primary-height);
	padding: var(--button--primary-padding);
	transition: all var(--transition-duration-short);
	box-shadow: var(--button--primary-shadow);
}

.button--primary:hover,
.button--primary:active {
	color: var(--button--primary--hover-color);
	background: var(--button--primary--hover-background);
}

.button--secondary {
	color: var(--button--secondary-color);
	background: var(--button--secondary-background);
	border: var(--button--secondary-border);
	border-radius: var(--button--secondary-border-radius);
	font-size: var(--button--secondary-font-size);
	font-weight: var(--button--secondary-font-weight);
	font-family: var(--button--secondary-font-family);
	height: var(--button--secondary-height);
	line-height: var(--button--primary-height);
	letter-spacing: var(--button--secondary-letter-spacing);
	padding: var(--button--secondary-padding);
	text-align: center;
	transition: all var(--transition-duration-short);
	white-space: nowrap;
	box-shadow: var(--button--secondary-shadow);
}

.button--secondary:hover,
.button--secondary:active {
	color: var(--button--secondary--hover-color);
	background: var(--button--secondary--hover-background);
}
