@import "container/locals.css";

.accordion-list {
	padding: var(--accordion__list-padding);
	margin: var(--accordion__list-margin);
}

.accordion {
	margin: var(--accordion-margin);
	text-align: left;
	background-color: var(--accordion-background);
	box-shadow: var(--accordion-shadow);
	border-radius: var(--accordion-border-radius);
}

.accordion.selected .accordion__container {
	max-height: 1000rem;
}

.accordion.selected .accordion__anchor:after {
	transform: rotate(0);
}

.accordion__anchor {
	position: relative;
	display: block;
	text-decoration: none;
	padding: var(--accordion__anchor-padding);
	background: var(--accordion__anchor-background);
}

.accordion__anchor:before,
.accordion__anchor:after {
	content: '';
	position: absolute;
	top: calc(50% - .2rem);
	left: var(--accordion__icon-left);
	width: var(--accordion__icon-size);
	height: .4rem;
	background: var(--accordion__icon-color);
}

.accordion__anchor:after {
	transform: rotate(90deg);
	transition: transform var(--transition-duration-short);
}

.accordion__icon {
	/* width: var(--accordion__icon-size); */
}

.accordion__title {
	color: var(--accordion__title-color);
	font-size: var(--accordion__title-font-size);
	font-family: var(--accordion__title-font-family);
	font-weight: var(--accordion__title-font-weight);
}

.accordion__anchor:hover .accordion__title {
	color: var(--accordion__title--hover-color);
}

.accordion__container {
	overflow: hidden;
	max-height: 0;
	transition: var(--accordion-transition);
}

.accordion__content {
	padding: var(--accordion__content-padding);
}
