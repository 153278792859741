.dropzone {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	min-height: 180px;
	background-color: #fff;
	border: 1px solid rgba(42, 41, 46, 0.2);
	padding: 6px 8px;
}

.dropzone:focus-within {
	outline: 3px dotted black;
	outline-offset: 2px;
}

.dropzone.uploaded {
	border: none;
	height: 100px;
	align-items: flex-start;
	padding: 2rem;
}

.dropzone__title {
	margin-bottom: 5px;
	margin-top: 5px;
}

.dropzone__label {
	text-decoration: underline;
}

.dropzone__label:hover {
	text-decoration: none;
	cursor: pointer;
}

.dropzone__file .file__metadata {
	margin-top: 10px;
	color: grey;
}

.dropzone__file .file__remove svg 	{
	height: 20px;
	margin-left: 15px;
	vertical-align: middle;
	width: 20px;
}

.mobile__show {
	display: none;
}

.file-error {
	color: red;
}

@media screen and (max-width: 640px) {
	.mobile__hide {
		display: none;
	}
	.mobile__show {
		display: inline-block;
	}
}