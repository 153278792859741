@import "variables.css";

.footer--primary {
	width: 100%;
	padding: 4rem 0 0 0;
	font-size: 1.5rem;
	text-align: center;
	background: var(--color-branding);
	display: flex;
	flex-wrap: wrap;
	flex-shrink: 0;
	justify-content: center;
	/* box-shadow: var(--shadow-small) inset; */
}

.footer--primary .menu-modal__item .card__content {
	color: var(--color-grey-dark);
}

.footer--primary a:not(.button--primary),
.footer__copyright {
	color: var(--color-grey-dark);
}

.footer--primary a:not(.button--primary):hover,
.footer--primary a:not(.button--primary):focus-visible {
	color: var(--color-black);
}

.footer__list {
	order: 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 0 0 2rem;
	padding: 0 0 2rem 0;
	width: 100%;
	list-style: none;
}

.footer__list li {
	margin: 0 1rem;
}

.footer__list li::before {
	content: none;
}

.footer__copyright {
	color: var(--color-grey-dark);
}

.footer__logo {
	order: 2;
	display: block;
	padding-bottom: 2rem;
	width: 100%;
}

.footer__listicons {
	padding: 0;
	margin: 0;
	margin: 0 0 2rem;
}

.footer__listicons li{
	display: inline-block;
	margin: 0 20px 0 20px;
}

@media only screen and (max-width: 640px){
	.footer__list {
		flex-direction: column;
	}

	.footer__list .footer__copyright {
		margin-top: 20px;
	}
}